import { markPerformanceAPI, measurePerformanceAPI } from '../../analytics-library/entry';
import { callback } from './entry';

export default async (): Promise<void> => {
  markPerformanceAPI('Entry script loaded', { detail: 'HAWKLINKS' });
  await callback();
  markPerformanceAPI('Hawklinks process completed', { detail: 'HAWKLINKS' });
  measurePerformanceAPI('Time between from script loaded to rewritten link return', {
    start: 'Entry script loaded',
    end: 'Hawklinks process completed',
    detail: `HAWKLINKS`,
  });
};
