import Uri from 'urijs';

export default class SLReset {
  trackedLinkPattern: string;

  resetParam: string;

  constructor() {
    this.trackedLinkPattern = 'go.redirectingat.com';
    this.resetParam = 'url';
  }

  getResetUrl(url: string): string | null {
    // Handle resetting pre affiliate SL links - we need a slightly different approach to HL resets
    if (url.indexOf(this.trackedLinkPattern) >= 0) {
      const urlObject = new Uri(url);
      const query = urlObject.query(true);

      const result = query[this.resetParam];

      if (Array.isArray(result)) {
        return result.shift();
      }
      return result;
    }

    return null;
  }
}
