import Uri from 'urijs';

export default class ExpressVPNLegacyReset {
  trackedLinkPattern: string;

  constructor() {
    this.trackedLinkPattern = 'xvtelink.com';
  }

  getResetUrl(url: string): string | null {
    if (url.indexOf(this.trackedLinkPattern) !== -1) {
      // We assume if the trackedLinkPattern is in the link, it's most likely in the hostname
      const urlObject = new Uri(url);
      urlObject.hostname(urlObject.hostname().replace('xvtelink.com', 'expressvpn.com'));
      return urlObject.toString();
    }

    return null;
  }
}
