export enum ClickType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  RETAILER = 'retailer',
}

export const getClickType = (isInternalLink: boolean, processingSource: string): ClickType => {
  if (isInternalLink) {
    return ClickType.INTERNAL;
  }

  if (processingSource === 'none') {
    return ClickType.EXTERNAL;
  }

  return ClickType.RETAILER;
};
