import type { Site } from '../../types/Site';
import { defaultSite, sites } from '../../utils/getSite';

export default class DefaultReset {
  site: Site;

  constructor(site) {
    this.site = site;
  }

  /**
   * Example input: http://www.jdoqocy.com/click-1111111-13359547?url=https%3A%2F%2Fwww.worldwidestereo.com%2Fproducts%2Frega-planar-3-turntable%3Fsku%3D172513&sid=whathifi-1111111111111111111",
   * Example output: http://www.jdoqocy.com/click-1111111-13359547?url=https%3A%2F%2Fwww.worldwidestereo.com%2Fproducts%2Frega-planar-3-turntable%3Fsku%3D172513&sid=hawk-custom-tracking",
   */
  getResetUrl(url: string): string | null {
    const currentSite = this.site && this.site.code;
    const detectedSiteCodesInUrl = sites
      .concat([defaultSite])
      .filter((site) => url.includes(site.code));

    const rewrittenUrlRegex = new RegExp(
      `(=(\\w+-\\w{2}-[0-9]{4,}|\\w+-\\w{2}-custom-tracking|\\w+-[0-9]{4,}|\\w+-custom-tracking))`,
      'g',
    );
    const regexGroupMatches = url.match(rewrittenUrlRegex) as RegExpMatchArray;
    let regexMatchWithSite: string | undefined = '';

    for (const site of detectedSiteCodesInUrl) {
      if (regexGroupMatches?.length > 0) {
        // https://www.amazon.co.uk/Black-Friday-Deals/b/ref=nav_swm_bf_swm?ie=UTF8&node=161428031&pf_rd_p=484b15be-fd82-4db5-b7af-6bf9dc2ba9b4&pf_rd_s=nav-sitewide-msg&pf_rd_t=4201&pf_rd_i=navbar-4201&pf_rd_m=A3P5ROKL5A1OLE&pf_rd_r=YJK0B4FDXYYJB8H953G5&pf_rd_r=YJK0B4FDXYYJB8H953G5&pf_rd_p=484b15be-fd82-4db5-b7af-6bf9dc2ba9b4&tag=blah&ascsubtag=trd-custom-tracking-21
        // the regex is going to pick up also "navbar-420"
        // so we check for the site to pick up the correct match group "=trd-custom-tracking-21"
        regexMatchWithSite = regexGroupMatches.find(
          (match) =>
            (match.includes(currentSite) || match.includes(site.code)) && match.includes('='),
        );
        if (regexMatchWithSite) {
          return url.replace(regexMatchWithSite, '=hawk-custom-tracking');
        }
      }
    }
    return null;
  }
}
