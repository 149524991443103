export default function getUrlParameters(whiteList?: string[]): Record<string, string> {
  const result: Record<string, string> = {};

  if (typeof window !== 'undefined') {
    // Allow modification of api requests from URL (e.g. testing other
    // territories locally (e.g. AU))
    const urlParams = window.location.search.match(/([^&?]*)=([^&?]*)/g);

    if (urlParams && urlParams.length > 0) {
      urlParams.forEach((param) => {
        const equalPos = param.indexOf('=');
        const name = param.substring(0, equalPos);
        if (!whiteList || whiteList.indexOf(name) !== -1) {
          result[name] = decodeURIComponent(param.substring(equalPos + 1)).replace(/\+/g, ' ');
        }
      });
    }
  }

  return result;
}
