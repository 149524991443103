import type { Site } from '../../types/Site';
import BaseMerchant from './BaseMerchant';

export default class PHGMerchant extends BaseMerchant {
  mid: string | number | undefined;

  adId?: string | number;

  constructor(
    id: number,
    name: string,
    hostname: string,
    mid: string | number | undefined,
    adId?: string | number,
    path?: string | null,
    targetDomain?: string | null,
    site?: Site,
  ) {
    super(id, name, hostname, site, path);
    this.mid = mid;
    this.adId = adId;
    this.trackedLinkPattern = targetDomain || 'www.prf.hn';
  }

  rewrite(url: string): string {
    // https://prf.hn/click/camref:1011lIFB/pubref:custom/destination:http%3A%2F%2Fexample
    if (this.adId) {
      return `https://${this.trackedLinkPattern}/click/camref:${
        this.adId
      }/pubref:hawk-custom-tracking/destination:${encodeURIComponent(url)}`;
    }

    return url;
  }
}
