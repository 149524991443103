import Uri from 'urijs';

export default class CJReset {
  allTrackedLinkPatterns: string[];

  resetParam: string;

  constructor() {
    this.allTrackedLinkPatterns = [
      'anrdoezrs.net',
      'kqzyfj.com',
      'tkqlhce.com',
      'dpbolvw.net',
      'jdoqocy.com',
    ];
    this.resetParam = 'url';
  }

  getResetUrl(url: string): string | null {
    for (const trackedLinkPattern of this.allTrackedLinkPatterns) {
      if (url.indexOf(trackedLinkPattern) !== -1) {
        const urlObject = new Uri(url);
        const queryObject = urlObject.query(true);

        // Links like: http://www.anrdoezrs.net/click-8900245-11517614-1435800121000?url=https%3A%2F%2Fwww.newegg.com%2FProduct%2FProduct.aspx%3FItem%3DN82E16834850877
        if (queryObject[this.resetParam]) {
          return queryObject[this.resetParam];
        }

        // Extra check for links like: https://www.anrdoezrs.net/links/6361382/type/dlg/sid/hawk-custom-tracking/http://www.argos.co.uk/static/ArgosPromo3/includeName/black-friday.htm
        const urlMatch = url.match(/type\/dlg(\/sid\/.+?)?\/(.+)$/);
        if (urlMatch && urlMatch[2]) {
          return decodeURIComponent(urlMatch[2]);
        }

        // Reset tracking for links like: http://www.jdoqocy.com/click-8900250-10457640?sid=ttrenUS43Review119
        const numberMatch = urlObject.path().match(/\/click-\d+-\d+/);
        if (numberMatch) {
          urlObject.removeQuery('sid');
          urlObject.addQuery('sid', 'hawk-custom-tracking');
          return urlObject.toString();
        }
      }
    }

    return null;
  }
}
