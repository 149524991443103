import Uri from 'urijs';

export default class LSReset {
  trackedLinkPattern: string;

  resetParam: string;

  constructor(targetDomain?: string) {
    this.trackedLinkPattern = targetDomain || 'click.linksynergy.com';
    this.resetParam = 'murl';
  }

  getResetUrl(url: string): string | null {
    if (url.indexOf(this.trackedLinkPattern) !== -1) {
      const urlObject = new Uri(url);
      const queryObject = urlObject.query(true);

      if (queryObject[this.resetParam]) {
        return queryObject[this.resetParam];
      }

      // https://click.linksynergy.com/fs-bin/click?id=Cty0dj6o3sg&offerid=330845.186&type=3&subid=TTRisp
      const urlMatch = urlObject.path().match(/\/fs-bin\/click/);
      if (urlMatch) {
        urlObject.removeQuery('subid');
        urlObject.addQuery('subid', 'hawk-custom-tracking');
        return urlObject.toString();
      }
    }

    return null;
  }
}
