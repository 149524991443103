import GenericMerchant from './GenericMerchant';

export default class CableguysMerchant extends GenericMerchant {
  rewrite(url: string): string {
    // format: https://partners.cableguys.com/go.cgi?pid=36&wmid=cc&cpid=1&subid=hawk-custom-tracking&target=https://www.cableguys.com/
    const baseUrl = url;
    const rewrittenUrl = super.rewrite(`https://${this.trackedLinkPattern}/go.cgi`);
    return `${rewrittenUrl}&target=${baseUrl}`; // so that url is not encoded as per deeplink format
  }
}
