import Uri from 'urijs';
import BaseMerchant from './BaseMerchant';
import type { Site } from '../../types/Site';

export default class EbayMerchant extends BaseMerchant {
  mid: string | number | undefined;

  trackingId: string | number;

  accountId: string | number;

  constructor(
    id: number,
    name: string,
    mid: string | number | undefined,
    hostname: string,
    path?: string | null,
    site?: Site,
    accountId?: string | number,
    trackingId?: string | number,
  ) {
    super(id, name, hostname, site, path);
    this.mid = mid;

    // Default to eBay US ID if trackingId is not known (to prevent broken links)
    this.trackingId = trackingId || '711-53200-19255-0';

    // Default to main accountId if not known (to prevent broken links)
    this.accountId = accountId || 5337827784;
  }

  rewrite(url: string): string {
    // Match URLs like https://www.ebay.com/..., http://ebay.de/..., etc., but not ebayaffiliate.com
    if (url.match(/[/.]ebay(?:\.\w{2,3}){1,2}[/$]/)) {
      const urlObject = new Uri(url);
      urlObject.setQuery('mkevt', '1');
      urlObject.setQuery('mkcid', '1');
      urlObject.setQuery('mkrid', String(this.trackingId));
      urlObject.setQuery('campid', String(this.accountId));
      urlObject.setQuery('customid', 'hawk-custom-tracking');

      return String(urlObject);
    }

    return url;
  }
}
