import Uri from 'urijs';
import BaseMerchant from './BaseMerchant';
import type { Site } from '../../types/Site';

export default class NordMerchant extends BaseMerchant {
  constructor(id: number, name: string, hostname: string, path?: string | null, site?: Site) {
    super(id, name, hostname, site, path);
    this.trackedLinkPattern = 'go.nordvpn.net';
  }

  rewrite(url: string): string {
    const affiliateId = 3013;
    const offerId = 18;
    const source = 'affiliate';

    const innerUrlObject = new Uri(url);
    innerUrlObject.addQuery('utm_source', `aff${affiliateId}`);
    innerUrlObject.addQuery('aff_id', affiliateId);
    innerUrlObject.addQuery('utm_medium', 'affiliate');
    innerUrlObject.addQuery('utm_term', source);
    innerUrlObject.addQuery('utm_content', 'hawk-custom-tracking');
    innerUrlObject.addQuery('utm_campaign', 'off18');
    innerUrlObject.addQuery('aff_source', source);
    // innerUrlObject.addQuery('aff_transaction_id', customTrackingString); // We can't have sub-tag added twice
    const urlObject = new Uri(`https://${this.trackedLinkPattern}/aff_c`);
    urlObject.addQuery('offer_id', offerId);
    urlObject.addQuery('aff_id', affiliateId);
    urlObject.addQuery('url', innerUrlObject.toString());

    return urlObject.toString();
  }
}
