import { Site } from '../../types/Site';

import defaultSite from './defaultSite';
import postProcessSite from './postProcessSite';
import sites from './sites';

export default (href: string, requestedSite: string, keywords: string[]): Site => {
  href = href || '';
  requestedSite = (requestedSite || '').toLowerCase();
  let site = sites.find((site) => {
    // TODO: Replace with single vanillaName property (https://purch1.atlassian.net/browse/HAWK-6112)
    const previewRegexName = new RegExp(`(preview.vanilla.tools/[^/]+/${site.name})`);
    const previewRegexCode = new RegExp(`(preview.vanilla.tools/[^/]+/${site.code})`);
    return (
      site.code === requestedSite ||
      site.name === requestedSite ||
      site.vanillaName === requestedSite ||
      href.search(`/${site.code}-`) >= 0 ||
      href.search(`${site.code}.html`) >= 0 ||
      href.indexOf(`${site.alias}.`) >= 0 || // stage/live site for an additional domain
      href.indexOf(`${site.url}`) >= 0 ||
      href.search(`/${site.name}/`) >= 0 ||
      href.search(`/${site.vanillaName}/`) >= 0 ||
      href.search(`/${site.code}/`) >= 0 ||
      href.indexOf(`${site.urlStage}`) >= 0 || // stage - for sites where a different config needs to be detected based on path marieclaireus/marieclaireusshop
      previewRegexName.test(href) ||
      previewRegexCode.test(href)
    );
  });
  // Return default config if no site was matched
  if (!site) {
    site = defaultSite;
  }

  // Add a custom tracking attribute using the site code
  return postProcessSite(site, keywords);
};
