import { Site } from '../types/Site';

/**
 * Consider links with [site]-custom-tracking to be already affiliated,
 * so we don't try to rewrite them again

 */
export const isAlreadyAffiliatedWithPlaceholderTracking = (url: string): boolean => {
  return (url || '').search('hawk-custom-tracking') >= 0;
};

/**
 *
 */
export const isAlreadyAffiliatedWithRewrittenTracking = (url: string, site: Site): boolean => {
  if (site && site.code && url) {
    return (
      url.search(new RegExp(`${site.code}-\\d+`, 'g')) >= 0 ||
      url.search(new RegExp(`${site.code}-\\w+-\\d+`, 'g')) >= 0
    );
  }
  return false;
};
