import {
  apstagAttributes,
  getAmazonApstagAttributesForHref,
} from '../../sharedModules/apstag/getAttributes';
import type { Site } from '../../types/Site';
import type HawkLinks from '../../utils/hawklinks';
import type SkimLinks from '../../utils/skimlinks';

import { getJSON } from './ajax';
import postProcessElement from './postProcessElement';

export default async (
  links: HTMLAnchorElement[],
  processors: (HawkLinks | SkimLinks)[],
  site: Site,
  area: string,
  articleName: string,
  articleUrl: string,
  widgets: HTMLElement[],
  articleId: string | null,
): Promise<void> => {
  for (const [index, el] of links.entries()) {
    for (const processor of processors) {
      const result = await processor.processElement(el, getJSON);

      if (result.rewritten) {
        el.setAttribute('data-hl-processed', result.source);
      }
    }

    if (!el.getAttribute('data-hl-processed')) {
      el.setAttribute('data-hl-processed', 'none');
    }

    if (el.href) {
      const hrefAttributes = getAmazonApstagAttributesForHref(el.href);
      if (
        hrefAttributes[apstagAttributes.asin] &&
        hrefAttributes[apstagAttributes.tag] &&
        hrefAttributes[apstagAttributes.ascsubtag]
      ) {
        Object.keys(hrefAttributes).forEach((key) => {
          if (hrefAttributes[key] === null) {
            el.removeAttribute(key);
          } else {
            el.setAttribute(key, String(hrefAttributes[key]));
          }
        });
      }
    }

    // Indicate that this link has been parsed by HawkLinks - moved here
    // so it is only added after HL & SL have run
    await postProcessElement({
      el,
      index,
      site,
      territory: area,
      articleName,
      articleUrl,
      totalLinks: links.length,
      widgets,
      articleId,
    });
  }

  // Record that all links have been processed for cypress
  window.hawklinks = {
    ...window.hawklinks,
    complete: true,
  };
};
