import { AnalyticsData } from '../../types/Analytics';
import getFreyrData from '../getFreyrData';
import { getHawk, updateHawk } from '../../utils/global';
import sendFreyr from '../sendFreyr';
import getData from '../getData';
import { GAConfig } from '../../types/GA';
import sendGA from '../sendGA';

const sendFreyrEvent = async (data: AnalyticsData, skip?: boolean): Promise<void> => {
  const freyrData = getFreyrData(data);

  // Store the data in the global object so we can check it in cypress tests
  // This is done here so that it is only used for client side GA - setting it globally in node would cause the memory to increase over time
  const { analytics } = getHawk();
  updateHawk({
    analytics: {
      ga: analytics?.ga || [],
      freyr: (analytics?.freyr || []).concat(freyrData),
    },
  });

  // Store the analytics data, but don't send the event in some instances (e.g. console)
  // We also don't await sendGA as we don't need to wait for it to finish
  if (!skip) {
    sendFreyr(freyrData);
  }
};

const sendGAEvent = (
  data: AnalyticsData,
  ga: GAConfig | null,
  el: HTMLElement | null,
  skip: boolean,
  flattenEmptyValues: boolean,
): void => {
  if (ga) {
    const gaData = getData(data, ga.format, el, flattenEmptyValues);

    // Store the data in the global object so we can check it in cypress tests
    // This is done here so that it is only used for client side GA - setting it globally in node would cause the memory to increase over time
    const { analytics } = getHawk();
    updateHawk({
      analytics: {
        ga: (analytics?.ga || []).concat(gaData),
        freyr: analytics?.freyr || [],
      },
    });

    // Store the analytics data, but don't send the event in some instances (e.g. console)
    // We also don't await sendGA as we don't need to wait for it to finish
    if (!skip) {
      sendGA(gaData, ga.id, ga.sendPageView);
    }
  }
};

export { sendFreyrEvent, sendGAEvent };
