import { GAData } from '../types/GA';

/**
 * Load the GA script and send a page view event if it hasn't already been loaded
 */
const addGA = (): Promise<boolean> => {
  return new Promise((res) => {
    // GA is already on the page - so only setup the tracker if it doesn't already exist
    if (typeof window !== 'undefined' && window.ga && window.ga.getAll) {
      res(false);
    } else {
      // GA isn't on the page, so load the script, setup the tracker and send a pageview event
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        "<script>(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');</script>",
        'text/html',
      );
      const script = doc.querySelector('script');

      if (script) {
        let count = 0;
        const interval = setInterval(() => {
          const head = document.querySelector('head');
          if (head) {
            head.appendChild(script);
            clearInterval(interval);
            res(true);
          } else if (count > 10) {
            clearInterval(interval);
            res(false);
          }
          count++;
        }, 300);
      }
    }
  });
};
/**
 * Send affiliate-related event data to GA.
 *
 * We assume that the page (environment) has set up GA and default tracker
 *  1) Create window.ga
 *  2) Create a default tracker
 *  But if this isn't the case, we will load the script and setup a tracker ourselves
 *
 * We create our own tracker based on the default tracker (that's why we need the default tracker
 * to be created). We also ensure the Beacon transport method is used (if supported), which ensures
 * the page unload is not delayed (out-click):
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#transport
 *
 * We expect the data to contain the eventLabel, eventAction, eventCategory and all custom dimensions.
 *
 * @param {GAData} data
 * @param {string} id
 * @param {boolean} sendPageView
 */
export default async (data: GAData, id: string, sendPageView?: boolean): Promise<void> => {
  await addGA();
  if (typeof window !== 'undefined' && window.ga) {
    const tracker = window.ga.getByName && window.ga.getByName('hawkWidgetsAffiliate');
    if (!tracker) {
      window.ga('create', id, 'auto', 'hawkWidgetsAffiliate');
    }

    if (sendPageView) {
      window.ga('hawkWidgetsAffiliate.send', 'pageview');
    }

    // Send the event data using 'hawkWidgetsAffiliate' tracker (which uses Beacon)
    window.ga('hawkWidgetsAffiliate.set', 'transport', 'beacon');
    window.ga('hawkWidgetsAffiliate.send', 'event', data);
  }
};
