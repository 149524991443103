/**
 * Get the user agent of the current browser, but mock the value for tests - used in generateDataHash
 * @returns {*}
 */
export default (): string => {
  if (
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.userAgent &&
    process.env.NODE_ENV !== 'test'
  ) {
    return window.navigator.userAgent;
  }
  return '';
};
