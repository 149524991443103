import GenericMerchant from './GenericMerchant';

export default class PrivadoMerchant extends GenericMerchant {
  rewrite(url: string): string {
    if (!this.parameters) {
      return url;
    }

    // if link already pre-affiliated
    if (/a_aid/.test(url) && /chan/.test(url)) {
      return url;
    }

    // format https://privadovpn.com/best-free-vpn/#a_aid=FuturePLC&chan=f1a
    const rewrittenUrl = super.rewrite(url);
    if (url.endsWith('/')) {
      return rewrittenUrl.replace('/?', '/#');
    }
    return rewrittenUrl.replace('?', '/#');
  }
}
