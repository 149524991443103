import Uri from 'urijs';
import type { Site } from '../../types/Site';

export default class Merchant {
  id: number;

  name: string;

  hostname: string;

  site?: Site;

  path?: string | null;

  network: string;

  dotHostname: string;

  url: string;

  trackedLinkPattern?: string | null;

  /**
   * Every Merchant must specify its name and URL pattern to find its links.
   */
  constructor(id: number, name: string, hostname: string, site?: Site, path?: string | null) {
    this.id = id;
    this.name = name;
    this.network = 'unknown';
    this.hostname = hostname;
    this.dotHostname = `.${this.hostname}`;
    this.url = this.hostname + (this.path ? `/${this.path}` : '');
    this.path = path;
    this.site = site;
    /** @type {string|null|undefined} */
    this.trackedLinkPattern = null; // This should be set by each Merchant class specifically
  }

  /**
   * @param {string} hostname
   * @param {string} path
   * @returns {boolean}
   */
  isMatch(hostname: string, path: string): boolean {
    // Already affiliated link
    if (
      this.trackedLinkPattern?.toLowerCase() &&
      hostname.toLowerCase().indexOf(this.trackedLinkPattern.toLowerCase()) !== -1
    ) {
      return false;
    }

    // Make sure the actual URL _hostname_ (not just domain) contains the pattern provided
    // by hl-merchant
    // Also ensure that URL conaining another subdomain matches:
    // (1) Link hostname === this.hostname (exact match: mobiles.co.uk/blah -> mobiles.co.uk)
    // (2) Link hostname is superset of this.hostname (a.b.mobiles.co.uk/blah -> mobiles.co.uk)
    // (3) We must not match another domain with similar suffix (affordablemobiles.co.uk -> mobiles.co.uk)
    if (
      hostname.toLowerCase() === this.hostname.toLowerCase() ||
      hostname.toLowerCase().indexOf(this.dotHostname.toLowerCase()) >= 0
    ) {
      // If path has been specified, make sure the link contains it
      // (1) Path is not specified OR (2) Path is contained in the link
      if (!this.path || path.startsWith(this.path)) {
        return true;
      }
    }

    return false;
  }

  treatUnchangedLinkAsProcessed(): boolean {
    return false;
  }

  /**
   * This is to set up a map of domain -> [merchant], so that we can find the relevant
   * merchants in O(1).
   *
   * There is usually just one merchant for a base domain, but even when there are more
   * (microsoft.com/en-us and microsoft.com/en-ca), the number is rather small so that
   * the lookup is done in constant time.
   *
   * We could use something along the lines of /.+?([^\.]+.{1,7})$/ on
   * this.hostname, but Uri.js seems to be doing a pretty good job on
   * identifying the base domain name.
   *
   * @returns {string}
   */
  getMerchantDomain(): string {
    const parts = {
      hostname: this.hostname,
      path: this.path,
    };
    const merchantUri = new Uri(parts);
    return merchantUri.domain().toLocaleLowerCase();
  }

  /**
   * @param {string} url
   * @returns {string}
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rewrite(url: string): string {
    throw new Error(`Rewrite function hasn't been specified for ${this.name}`);
  }
}
