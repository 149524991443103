import Uri from 'urijs';

export default class EbayReset {
  // eslint-disable-next-line class-methods-use-this
  getResetUrl(url: string): string | null {
    if (url.indexOf('rover.ebay.com') !== -1) {
      const urlObject = new Uri(url);
      const queryObject = urlObject.query(true);

      // Links like: https://rover.ebay.com/rover/1/705-53470-19255-0/1?mpre=https%3A%2F%2Fwww.ebay.com.au%2Fitm%2FAssassins-Creed-Valhalla-Xbox-Series-X-Xbox-One-Game-NEW%2F324337403084%3F_trkparms%3D5373%253A5000011038%257C5374%253AElectronics%257C5079%253A5000011054&campid=5338048699&toolid=20008
      // -> https://www.ebay.com.au/itm/Assassins-Creed-Valhalla-Xbox-Series-X-Xbox-One-Game-NEW/324337403084?_trkparms=5373%3A5000011038%7C5374%3AElectronics%7C5079%3A5000011054
      if (queryObject.mpre) {
        return queryObject.mpre;
      }
    }

    return null;
  }
}
