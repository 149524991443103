export const createTimeOutedInterval = (
  intervalCheckCallback: () => boolean,
  intervalCallback: () => void,
  timeoutCallback?: () => void,
  interval = 100,
  timeout = 5000,
): void => {
  let kiosqInerval;
  const kiosqTimeout = window.setTimeout(() => {
    window.clearInterval(kiosqInerval);
    window.clearTimeout(kiosqTimeout);
    if (timeoutCallback) {
      timeoutCallback();
    }
  }, timeout);
  kiosqInerval = window.setInterval(() => {
    if (intervalCheckCallback()) {
      window.clearTimeout(kiosqTimeout);
      window.clearInterval(kiosqInerval);
      intervalCallback();
    }
  }, interval);
};
