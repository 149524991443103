/**
 * window.dfp_config.keywords might not be defined when we check
 * (e.g. on TRD script is loaded before the dfp_config is set)
 * So check until it is defined, wait for up to 1 seconds
 */
export default (): Promise<string[]> => {
  return new Promise((resolve) => {
    let count = 0;
    const interval = setInterval(() => {
      if (
        window.dfp_config &&
        window.dfp_config.keywords &&
        window.dfp_config.keywords.length > 0
      ) {
        // Config found so return the keywords
        clearInterval(interval);
        resolve(window.dfp_config.keywords.split(','));
      } else if (count > 5) {
        // Don't wait any longer and return an empty []
        clearInterval(interval);
        resolve([]);
      }
      count++;
    }, 200);
  });
};
