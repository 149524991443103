import Uri from 'urijs';
import BaseMerchant from './BaseMerchant';
import type { Site } from '../../types/Site';

export default class LSMerchant extends BaseMerchant {
  mid: string | number | undefined;

  trackingId: string | number | undefined;

  constructor(
    id: number,
    name: string,
    mid: string | number | undefined,
    trackingId: string | number | undefined,
    hostname: string,
    path?: string | null,
    site?: Site,
  ) {
    super(id, name, hostname, site, path);
    this.mid = mid;
    this.trackingId = trackingId;
    this.trackedLinkPattern = 'click.linksynergy.com';
  }

  rewrite(url: string): string {
    if (!this.trackingId || !this.trackedLinkPattern || !this.mid) {
      return url;
    }

    const urlObject = new Uri(`https://${this.trackedLinkPattern}/deeplink`);
    urlObject.addQuery('id', this.trackingId);
    urlObject.addQuery('mid', this.mid);
    urlObject.addQuery('u1', 'hawk-custom-tracking');
    urlObject.addQuery('murl', url);

    return urlObject.toString();
  }
}
