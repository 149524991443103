import Uri from 'urijs';

export default class AWReset {
  trackedLinkPattern: string;

  resetParams: string[];

  constructor() {
    this.trackedLinkPattern = 'awin1.com';
    this.resetParams = ['p', 'ued'];
  }

  getResetUrl(url: string): string | null {
    if (url.indexOf(this.trackedLinkPattern) !== -1) {
      const urlObject = new Uri(url);
      const queryObject = urlObject.query(true);
      const resetParam = this.resetParams.find((param) => queryObject[param]);

      if (resetParam) {
        /** @type {string|null} */
        let innerUrl;
        const p = queryObject[resetParam];
        if (Array.isArray(p)) {
          innerUrl = p.shift();
        } else {
          innerUrl = p;
        }

        if (Number.isNaN(parseFloat(innerUrl || ''))) {
          const fragment = urlObject.hash(); // Returns string "#world" (leading #)
          return innerUrl + fragment; // Fragment contains the hash symbol already, if there was a fragment
        }
      }
    }

    return null;
  }
}
