import Uri from 'urijs';
import { markPerformanceAPI } from '../analytics-library/entry';
import Merchant from '../hawklinks/merchants/BaseMerchant';
import { getJSON } from '../hawklinks/browser/ajax';
import getUrl from '../hawklinks/browser/getUrl';
import {
  isAlreadyAffiliatedWithPlaceholderTracking,
  isAlreadyAffiliatedWithRewrittenTracking,
} from './isAlreadyAffiliated';
import SLReset from '../hawklinks/resets/SLReset';
import { Site } from '../types/Site';
import { BaseReset, ProcessedLink } from './hawklinks';

/**
 * Creates a function that accepts a link url and document url and returns the rewritten skimlink
 */
const createRewrite =
  (skimlinksId?: number) =>
  (linkUrl: string, documentUrl: string): string => {
    const urlObject = new Uri('https://go.redirectingat.com');
    urlObject.addQuery('id', skimlinksId);
    urlObject.addQuery('xcust', 'hawk-custom-tracking');
    urlObject.addQuery('xs', 1);
    urlObject.addQuery('url', linkUrl);
    urlObject.addQuery('sref', documentUrl);

    return urlObject.toString();
  };

const getDomains = (linkUrls: string[], reset: BaseReset): string[] => {
  const domains = new Set<string>();
  linkUrls.forEach((url) => {
    const resetUrl = reset.getResetUrl(url);
    const uri = new Uri(resetUrl || url);
    const domain = uri.domain();
    domains.add(domain);
  });

  return Array.from(domains);
};

class SkimLinks {
  private domain: string;

  private skimlinksId: number | undefined;

  private getRewrittenLink: (linkUrl: string, documentUrl: string) => string;

  private rewriteAllDomains: boolean;

  private site: Site;

  private reset: BaseReset;

  private documentDomains: string[];

  private promise: undefined | Promise<string[]>;

  constructor(
    domain: string,
    skimlinksId: number | undefined,
    rewriteAllDomains: boolean,
    linkUrls: string[],
    site: Site,
  ) {
    this.domain = domain;
    this.skimlinksId = skimlinksId;
    this.getRewrittenLink = createRewrite(skimlinksId);
    this.rewriteAllDomains = rewriteAllDomains;
    this.site = site;
    this.reset = new SLReset();
    this.documentDomains = getDomains(linkUrls, this.reset);
  }

  /**
   * Determines whether a link can be rewritten
   * Rejects empty links, links to the same domains as the document, javascript:void(0) links
   * Rejects links with a domain on a blacklist or custom tracking (e.g. rewritten in widgets)
   */
  public isRewritable = (linkUrl: string): boolean => {
    // skip anchor links, links to the same domain and links with rewritten custom tracking
    if (
      linkUrl === '' ||
      linkUrl.indexOf('#') === 0 ||
      linkUrl.indexOf(this.domain) !== -1 ||
      isAlreadyAffiliatedWithRewrittenTracking(linkUrl, this.site)
    ) {
      return false;
    }
    // Do not let SkimLinks rewrite:
    //  - MFM (.co.uk and .com) links, as we own the site
    //  - ShoppingPartners links (MRD/DOTW), as they have exclusive contract with us
    //  - GetPrice, since they are CPC and we deal with them directly
    // Getty images - HAWK-3386
    // Istock photo - HAWK-3386
    const elUri = new Uri(linkUrl);
    const elHostname = elUri.hostname();
    const blacklistedUrls = [
      'myfavouritemagazines',
      'shoppingpartners2.futurenet.com',
      'go2jump.org',
      'getprice.com.au',
      'whistleout',
      'istockphoto.7eer.net',
      'istockphoto.com',
      'trk.aclktrkr.com',
      'gettyimages.com',
      'prf.hn',
      'tsohost.com',
      'adfarm.mediaplex.com',
      'altfarm.mediaplex.com',
    ];
    let stopRewrite = false;

    blacklistedUrls.forEach((url) => {
      if (elHostname.indexOf(url) >= 0) {
        stopRewrite = true;
      }
    });

    // Don't let skimlinks rewrite the link
    if (stopRewrite) {
      return false;
    }

    const elDomain = elUri.domain();

    // If domain is not set (such as "javascript:void(0)"), do not rewrite
    return Boolean(elDomain);
  };

  /**
   * Lazy getter for list of SkimLinks merchants
   */
  public getListOfMerchantDomains = (
    url: string,
    https: boolean,
    passedGetJSON: typeof getJSON,
  ): Promise<string[] | undefined> => {
    if (!this.promise) {
      if (!this.skimlinksId) {
        // Return empty list of merchants if no SkimLinks ID has been provided
        // This should be an unreachable statement, just a bit of defensiveness
        this.promise = Promise.resolve([]);
      } else {
        const skimData = {
          persistence: 1,
          data: JSON.stringify({
            pubcode: this.skimlinksId,
            domains: this.documentDomains,
            page: encodeURIComponent(url),
          }),
        };

        // The Skimlinks API will only allow https from an https site,
        // but we expect everything is https now (no http)
        const link = 'https://r.skimresources.com/api/';

        this.promise = passedGetJSON<{ merchant_domains: string[] | undefined }>(link, skimData)
          .then((data) => {
            if (!data?.merchant_domains) {
              throw new Error('Merchant domains not available in SkimLinks API response');
            }
            return data.merchant_domains || [];
          })
          .catch((error) => {
            throw error;
          });
      }
    }

    return this.promise;
  };

  public processElement = async (
    el: HTMLAnchorElement,
    passedGetJSON: typeof getJSON,
  ): Promise<ProcessedLink> => {
    // No ID means that SkimLinks hasn't been initialised, skip processing
    if (!this.skimlinksId) {
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    // Link is affiliated inside a widget
    if (isAlreadyAffiliatedWithPlaceholderTracking(el.getAttribute('data-url') || '')) {
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    // The link has already been processed (hawklinks), or should not be processed (sponsored)
    if (el.getAttribute('data-hl-processed')) {
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    // skip already parsed links
    if (el.classList.contains('hawk-link-parsed')) {
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    if (el.getAttribute('data-no-affiliate-tracking')) {
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    const url = document.URL;
    const https = window.location.protocol === 'https:';

    if (this.rewriteAllDomains) {
      // Process the link immediately (regardless of the supported domains)
      return this.rewriteEl(el, []);
    }

    // Process the link as soon as the merchant domain list is available
    try {
      const merchantDomains = await this.getListOfMerchantDomains(url, https, passedGetJSON);
      return this.rewriteEl(el, merchantDomains);
    } catch (_) {
      // Ensure the SL error doesn't break rewriting of other links
      // console.log('Could not rewrite link via SL', error);
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }
  };

  public processLink = (
    url: string,
    merchantDomains: string[] = [],
    documentURL: string,
  ): ProcessedLink => {
    if (!this.skimlinksId) {
      return {
        url,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    /* Use the reset url for isRewritable to avoid the link being skipped because of domain
     * SL links include the page url as a param
     **/
    const resetUrl = this.reset.getResetUrl(url);
    const linkUrl = resetUrl || url;

    if (!this.isRewritable(linkUrl)) {
      return {
        url,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    const elUri = new Uri(linkUrl);
    const elDomain = elUri.domain();

    if (this.rewriteAllDomains || merchantDomains.indexOf(elDomain) >= 0) {
      return {
        url: this.getRewrittenLink(linkUrl, documentURL),
        merchant: {
          name: `SkimLinks - ${elDomain}`,
        } as Merchant,
        rewritten: true,
        source: 'skimlinks',
      };
    }

    return {
      url,
      merchant: null,
      rewritten: false,
      source: 'skimlinks',
    };
  };

  /**
   *
   * @param el
   * @param {array} merchantDomains
   * @returns {boolean}
   */
  private rewriteEl = (el: HTMLAnchorElement, merchantDomains: string[] = []): ProcessedLink => {
    markPerformanceAPI('Skimlink Rewrite started', { detail: 'HAWKLINKS' });
    const result = this.processLink(getUrl(el), merchantDomains, document.URL);
    markPerformanceAPI('Skimlink Rewrite completed', { detail: 'HAWKLINKS' });
    if (result.rewritten) {
      el.setAttribute('data-url', el.href);
      el.href = result.url;
      if (result.merchant) {
        el.setAttribute('data-merchant-name', result.merchant.name);
      }
      el.setAttribute('data-hl-processed', 'skimlinks');

      return result;
    }

    return result;
  };
}

export default SkimLinks;
