import { Site } from '../../types/Site';

export const getArticleId = (urlArticleId: string, site: Site): string | null => {
  if (urlArticleId) {
    if (!window.analytics_ga_data) {
      window.analytics_ga_data = {};
    }
    return urlArticleId;
  }

  if (typeof window !== 'undefined' && window?.dfp_config?.article_id) {
    return window.dfp_config.article_id;
  }

  // Get the keywords for vanilla sites
  const gaData = window.analytics_ga_data;
  const platform = site?.ga?.format || 'vanilla';

  if (gaData) {
    switch (platform) {
      case 'vanilla':
        return gaData.dimension5;
      case 'ti':
        return gaData.dimension10;
      case 'cinemablend':
        return gaData.dimension3;
      case 'mozo':
        return gaData.dimension8;
      case 'pricepandaid':
      case 'pricepandath':
        return gaData.dimension1;
      case 'dennis':
        return gaData.dimension8;
      case 'getprice':
      case 'getpricenz':
        return gaData.dimension26;
      case 'pricepandasg':
      case 'pricepandamy':
      case 'pricepandaph':
        return gaData.dimension21;
      case 'shortlist':
        return gaData.dimension100;
      case 'whowhatwear':
        return gaData.dimension9;
      default:
        return null;
    }
  }
  return null;
};
