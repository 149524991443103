import { v4 as uuidV4 } from 'uuid';

import { AnalyticsData } from '../types/Analytics';

import { LinkData } from '../types/LinkData';

export default (
  totalLinks: number,
  articleId: string | null,
  links: LinkData[],
  prefix: string,
  componentType: string | null,
  type: string,
  index: number | null,
  clickType: string,
): AnalyticsData => {
  const data: AnalyticsData = {
    event: {
      category: 'Affiliates',
      prefix,
      flag: '',
      productType: '',
      component: {
        name: componentType || 'HawkLink',
        category: 'hawklinks',
      },
      type,
      clickType,
      label: links.map((link) => link.text).join(','),
      index,
      totalDeals: totalLinks,
      viewportTime: 0,
      battle: null,
      articleId: articleId as any, // TODO: remove after type fix in fie-analytics
      backgroundColor: '',
      elementIds: [],
      elementYPosition: null,
      paywallStatus: false,
    },
    products: links.map((link) => {
      return {
        matchId: null,
        merchant: link.merchant,
        model: {
          id: null,
          brand: null,
          name: link.modelName || null,
          parent: null,
        },
        currencyIso: '',
        price: 0,
        formattedPrice: '',
        wasPrice: null,
        serviceProvider: null,
        product: {
          primary: {
            revenueId: link.customTrackingId,
            linkDestination: link.url,
          },
        },
        name: link.text || link.modelName,
        labels: [],
        promos: [],
        preorder: false,
        url: link.url,
        customTrackingId: link.customTrackingId,
        startDate: null,
        endDate: null,
        type: '',
        timeRemaining: null,
        voucherCodeString: null,
        scope: null,
      };
    }),
    reviews: [],
  };

  const hawklinkId =
    typeof window !== 'undefined' && window.hawklinks && window.hawklinks.hawklinkId;
  data.event.elementIds = links.map(() => {
    if (hawklinkId) {
      return `${hawklinkId}`;
    }

    return uuidV4();
  });

  return data;
};
