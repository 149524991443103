import Uri from 'urijs';
import BaseMerchant from './BaseMerchant';
import type { Site } from '../../types/Site';

export default class GenericMerchant extends BaseMerchant {
  parameters: Record<string, string>;

  targetDomain?: string | null;

  trackingPath?: string | null;

  constructor(
    id: number,
    name: string,
    hostname: string,
    path?: string | null,
    parameters?: Record<string, string>,
    targetDomain?: string | null,
    site?: Site,
    trackingPath?: string | null,
  ) {
    super(id, name, hostname, site, path);
    this.parameters = parameters || {};
    this.targetDomain = targetDomain;
    this.trackingPath = trackingPath;
  }

  /**
   * Tests if a string is valid URL
   */
  isValidHttpUrl(string: string): boolean {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  rewrite(url: string): string {
    const urlObject = new Uri(url);
    for (const key in this.parameters) {
      if (typeof this.parameters[key] !== 'undefined') {
        urlObject.removeQuery(key);
        if (this.parameters[key] === 'hawk-url-replace') {
          urlObject.addQuery(key, url);
        } else {
          urlObject.addQuery(key, this.parameters[key]);
        }
      }
    }

    if (this.targetDomain) {
      if (!this.isValidHttpUrl(this.targetDomain)) {
        urlObject.hostname(this.targetDomain);
      } else {
        // the target domain is not really a domain, do not rewrite
        return url;
      }
    }

    if (this.trackingPath) {
      const pathInfo = this.trackingPath.split('#');
      const trackingPath = pathInfo[0];
      const fragment = pathInfo[1];
      urlObject.path(trackingPath);
      urlObject.fragment(fragment);
    }

    const newUrl = urlObject.toString();

    if (this.hostname === 'bluehost.com' && urlObject.hasQuery('page')) {
      const queryObject = urlObject.query(true);
      const pageQuery = queryObject.page;

      if (typeof pageQuery === 'string') {
        return newUrl.replace(Uri.encodeQuery(pageQuery), pageQuery);
      }
    }

    return newUrl;
  }

  treatUnchangedLinkAsProcessed(): boolean {
    return true;
  }
}
