import Uri from 'urijs';
import BaseMerchant from './BaseMerchant';
import type { Site } from '../../types/Site';

export default class HasOffersMerchant extends BaseMerchant {
  mid: string | number | undefined;

  accountId: string | number;

  parameters: Record<string, string>;

  constructor(
    id: number,
    name: string,
    mid: string | number | undefined,
    hostname: string,
    path?: string | null,
    site?: Site,
    targetDomain?: string,
    accountId?: string | number,
    parameters?: Record<string, string>,
  ) {
    super(id, name, hostname, site, path);
    this.mid = mid;
    this.trackedLinkPattern = targetDomain || 'fan.go2jump.org';
    this.accountId = accountId || 1;
    this.parameters = parameters || {};
  }

  rewrite(url: string): string {
    // https://www.hostinger.com/cloud-hosting
    // ->
    // https://www.hostg.xyz/aff_c?offer_id=61&aff_id=1631&source=trd&aff_click_id=trd-gb-8273440388253285000&url=https%3A%2F%2Fwww.hostinger.com%2Fcloud-hosting%3Futm_source%3Daff{affiliate_id}%26utm_campaign%3D{offer_id}%26session%3D{transaction_id}&aff_sub2=https://www.techradar.com/uk/web-hosting/best-web-hosting-service-websites
    const urlObject = new Uri(`https://${this.trackedLinkPattern}/aff_c`);
    urlObject.addQuery('offer_id', this.mid);
    urlObject.addQuery('aff_id', this.accountId);

    // Add URL ID if provided in the parameters. This is used for some HasOffers merchants such as IPVanish
    if ('url_id' in this.parameters) {
      urlObject.addQuery('url_id', this.parameters.url_id);
    }

    urlObject.addQuery('source', this.site && this.site.code);
    urlObject.addQuery('aff_click_id', 'hawk-custom-tracking');
    if ('utm_info' in this.parameters) {
      const innerUrlObject = new Uri(url);
      innerUrlObject.addQuery(this.parameters.utm_info);
      urlObject.addQuery('url', Uri.decode(innerUrlObject.toString()));
      urlObject.addQuery('aff_sub2', 'hawk-article-url');
    } else {
      urlObject.addQuery('aff_sub2', 'hawk-article-url');
      urlObject.addQuery('url', url);
    }
    return urlObject.toString();
  }
}
