import { getDataHash } from './getDataHash';
import getUserAgent from '../../utils/getUserAgent';
import getGoogleClickId from '../../utils/getGoogleClickId';

export const getCustomTrackingId = async (
  params: Record<string, unknown> = {},
  customTrackingId: string | null = null,
  date = new Date(),
): Promise<string> => {
  // Allow forcing the custom tracking for tests
  if (customTrackingId) {
    return Promise.resolve(customTrackingId);
  }

  // Use the user agent for the generate data hash - HAWK-5103
  params = params || {};
  params = {
    ...params,
    date: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
    userAgent: getUserAgent(),
    gclid: getGoogleClickId(),
  };

  // Remove metrics - generally based on time which prevents the same id from being generated
  // E.g. time since page load, viewport time, deal time remaining
  for (const key in params) {
    if (key.search(/metric/g) >= 0) {
      delete params[key];
    }
  }

  return getDataHash(params);
};
