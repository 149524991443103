import BaseFormatter from './BaseFormatter';
import { Attribute } from '../../types/Attribute';
import getGoogleClickId from '../../utils/getGoogleClickId';

export default class WhoWhatWearFormatter extends BaseFormatter {
  getCustomAttributes(): Attribute[] {
    return [
      {
        name: 'dimension9',
        getValue: this.getArticleId.bind(this),
      },
      {
        name: 'dimension123',
        getValue: this.getMatchIds.bind(this),
      },
      {
        name: 'dimension121',
        getValue: this.getMerchantNames.bind(this),
      },
      {
        name: 'dimension113',
        getValue: this.getIndex.bind(this),
      },
      {
        name: 'dimension114',
        getValue: this.getTotalDeals.bind(this),
      },
      {
        name: 'dimension120',
        getValue: this.getFormattedRetailPrices.bind(this),
      },
      {
        name: 'dimension33',
        getValue: () => 'null',
      },
      {
        name: 'dimension103',
        getValue: this.getServiceProviders.bind(this),
      },
      {
        name: 'dimension119',
        getValue: this.getProductNames.bind(this),
      },
      {
        name: 'dimension104',
        getValue: this.getProductTypes.bind(this),
      },
      {
        name: 'dimension106',
        getValue: this.getBackgroundColor.bind(this),
      },
      {
        name: 'dimension107',
        getValue: this.getCustomTrackingIds.bind(this),
      },
      {
        name: 'dimension108',
        getValue: this.getLabelsAndPromos.bind(this),
      },
      {
        name: 'dimension112',
        getValue: this.getUrls.bind(this),
      },
      {
        name: 'dimension109',
        getValue: this.getVoucherCodeString.bind(this),
      },
      {
        name: 'dimension110',
        getValue: this.getElementIds.bind(this),
      },
      {
        name: 'dimension111',
        getValue: this.getMerchantIds.bind(this),
      },
      {
        name: 'dimension126',
        getValue: this.getStartDate.bind(this),
      },
      {
        name: 'dimension115',
        getValue: this.getClickType.bind(this),
      },
      {
        name: 'dimension116',
        getValue: this.getReviewCodes.bind(this),
      },
      {
        name: 'dimension117',
        getValue: this.getReviewCounts.bind(this),
      },
      {
        name: 'dimension118',
        getValue: this.getReviewScores.bind(this),
      },
      {
        name: 'dimension122',
        getValue: getGoogleClickId,
      },
      {
        name: 'dimension124',
        getValue: this.getWasPrices.bind(this),
      },
      {
        name: 'dimension125',
        getValue: this.getEndDate.bind(this),
      },
      {
        name: 'metric1',
        getValue: this.getLoadTime.bind(this),
      },
      {
        name: 'metric24',
        getValue: this.getViewportTime.bind(this),
      },
      {
        name: 'metric25',
        getValue: this.getTimeRemaining.bind(this),
      },
      {
        name: 'metric27',
        getValue: this.getPageHeight.bind(this),
      },
      {
        name: 'metric28',
        getValue: this.getElementYPosition.bind(this),
      },
    ];
  }
}
