import HL from '../../utils/hawklinks';
import { MerchantDomain } from '../../utils/merchants';
import SL from '../../utils/skimlinks';
import processLinks from './processLinks';
import postProcessArticle from './postProcessArticle';
import getUrlParameters from './getUrlParameters';
import type { Site } from '../../types/Site';
import { handleInfiniteScrollArticle } from './handleInfiniteScrollArticle';
import { getLinks } from './getLinks';
import { getArticleId } from './getArticleId';

interface InitOptions {
  merchants: MerchantDomain[];
  area: string;
  tsid?: string;
  skimlinksId?: number;
  site: Site;
}

const init = async (options: InitOptions): Promise<void> => {
  const { merchants, area, tsid, skimlinksId } = options;
  let { site } = options;
  site = site || {};
  const { selector } = site;

  const articleName = document.title;
  const articleUrl = document.location.href;

  const { article_id: urlArticleId } = getUrlParameters();
  const articleId = getArticleId(urlArticleId, site);

  // Setup globals for easier debugging
  if (!window.hawklinks) {
    window.hawklinks = {};
  }
  window.hawklinks = {
    ...window.hawklinks,
    version: process.env.VERSION,
    site,
    articleName,
    articleUrl,
    tsid,
    skimlinksId,
  };

  // Get links that are not in a widget.
  const { linksToProcess, widgets, urls } = getLinks(selector);

  // Initialize processors = HawkLinks + SkimLinks
  const hawkLinks = new HL(document.domain, tsid, merchants, site);
  const skimLinksWithDomainCheck = new SL(document.domain, skimlinksId, false, urls, site);

  // Rewrite existing links with HawkLinks + SkimLinks right away
  await processLinks(
    linksToProcess,
    [hawkLinks, skimLinksWithDomainCheck],
    site,
    area,
    articleName,
    articleUrl,
    widgets,
    articleId,
  );

  handleInfiniteScrollArticle({
    hawkLinks,
    skimLinksWithDomainCheck,
    selector,
    site,
    area,
    articleId,
    articleName,
    articleUrl,
  });

  postProcessArticle(site, linksToProcess, widgets);
};

export default init;
