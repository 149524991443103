// This is essentially a wrapper for querySelectorAll, but returns the elements as an
// array, rather than NodeList so you
// can use all the Array prototype methods. Also it should make our code a bit smaller
// and cleaner, so for example this:
//
//  Array.prototype.slice.call(document.querySelectorAll("th.sortable")).
//      forEach(function(element){/* code goes here */});
//
// becomes:
//
//      HawkUtil.get("th.sortable").forEach(function(element){/* code goes here */});
//
// Which I think is a bit more readable and less scary
export default <T>(selector: string, context?: Document | HTMLElement): T[] => {
  let newContext = context;
  // The context is the element to search inside, if undefined, we should use document
  if (typeof newContext === 'undefined' || typeof newContext.querySelectorAll !== 'function') {
    newContext = document;
  }
  return Array.prototype.slice.call(newContext.querySelectorAll(selector));
};
