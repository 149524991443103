import Uri from 'urijs';

export default class AmznReset {
  getResetUrl(url: string): string | null {
    const urlObject = new Uri(url);
    if (urlObject.hostname().match('amzn..+')) {
      urlObject.hostname(urlObject.hostname().replace('amzn', 'amazon'));
      return urlObject.toString();
    }

    return null;
  }
}
