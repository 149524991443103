import { sendGAEvent, sendFreyrEvent } from '../../analytics-library/entry';
import getAnalyticsData from '../../utils/getAnalyticsData';
import type { Site } from '../../types/Site';
import { getClickType } from '../../utils/getClickType';

export default (
  el: HTMLAnchorElement,
  index: number,
  totalLinks: number,
  site: Site | Record<string, never>,
  articleId: string | null,
  articleUrl: string,
): void => {
  let linkUrl: URL | null = null;
  try {
    linkUrl = new URL(el.getAttribute('href') || '');
  } catch {
    linkUrl = null;
  }
  const isInternalLink = articleUrl.includes(linkUrl?.hostname || '');

  // The link has already been tracked -> prevent duplicate events
  if (el.getAttribute('data-hawk-tracked')) {
    return;
  }
  el.setAttribute('data-hawk-tracked', 'hawklinks');

  if (!isInternalLink) {
    // Set other properties for tracking
    el.setAttribute('target', '_blank');
    el.setAttribute('referrerpolicy', 'no-referrer-when-downgrade');

    if (el.getAttribute('data-hl-processed') !== 'none') {
      el.setAttribute('rel', 'sponsored noopener');
    }
  }

  el.setAttribute('data-google-interstitial', 'false');

  // Only register GA if the GA tracking hasn't been set up yet (from the widget)
  // Keep the label if it's been already set (such as for link in widgets)
  el.setAttribute('data-label', el.getAttribute('data-label') || el.textContent || '');

  const title = el.dataset && el.dataset.action ? el.dataset.action : 'HawkLink';
  const skipGA = window.hawklinks && window.hawklinks.skipGA;

  // Set up
  const trackClick = (event): void => {
    const prefix = 'Click from';
    const processingSource = el.getAttribute('data-hl-processed') || 'none';
    const clickType = getClickType(isInternalLink, processingSource);

    const analyticsData = getAnalyticsData(
      totalLinks,
      articleId as string, // TODO:remove after fix in fie-analytics to accept null in types

      [
        {
          url: el.getAttribute('data-url') || '',
          text: String(el.getAttribute('data-label') || el.textContent),
          customTrackingId:
            el.getAttribute('data-custom-tracking-id') && el.getAttribute('data-custom-tracking-id')
              ? el.getAttribute('data-custom-tracking-id')
              : null,
          merchant: {
            id:
              el.getAttribute('data-merchant-id') &&
              parseInt(String(el.getAttribute('data-merchant-id')), 10)
                ? parseInt(String(el.getAttribute('data-merchant-id')), 10)
                : null,
            name: el.getAttribute('data-merchant-name') || '',
            url: el.getAttribute('data-merchant-url') || '',
            network: el.getAttribute('data-merchant-network') || '',
          },
          modelName: el.getAttribute('data-model-name') || '',
          elementYPosition: el.offsetTop,
        },
      ],
      prefix,
      title,
      '',
      index,
      clickType,
    );

    if (site.ga) {
      sendGAEvent(analyticsData, site.ga, event.target, skipGA || false, false);
    }
    sendFreyrEvent(analyticsData, skipGA);
  };

  const click = (event): void => {
    // Track the click if it is not a middle click - middle clicks
    // don't fire onClick or onContextMenu events
    // Added to ensure Firefox doesn't fire both click events
    if (typeof event.button !== 'undefined' && event.button !== 1) {
      trackClick(event);
    }
  };

  const middleClick = (event): void => {
    // Track the click if it is a middle click - middle clicks don't
    // fire onClick or onContextMenu events
    if (typeof event.button !== 'undefined' && event.button === 1) {
      trackClick(event);
    }
  };

  el.addEventListener('click', click);
  el.addEventListener('contextmenu', click);
  el.addEventListener('mousedown', middleClick);
};
