import BaseFormatter from './BaseFormatter';
import { Attribute } from '../../types/Attribute';

export default class TIFormatter extends BaseFormatter {
  getCustomAttributes(): Attribute[] {
    return [
      {
        name: 'dimension10',
        getValue: this.getArticleId.bind(this),
      },
      {
        name: 'dimension45',
        getValue: this.getMatchIds.bind(this),
      },
      {
        name: 'dimension46',
        getValue: this.getMerchantNames.bind(this),
      },
      {
        name: 'dimension47',
        getValue: this.getIndex.bind(this),
      },
      {
        name: 'dimension48',
        getValue: this.getTotalDeals.bind(this),
      },
      {
        name: 'dimension49',
        getValue: this.getFormattedRetailPrices.bind(this),
      },
      {
        name: 'dimension50',
        getValue: this.getServiceProviders.bind(this),
      },
      {
        name: 'dimension51',
        getValue: this.getProductNames.bind(this),
      },
      {
        name: 'dimension60',
        getValue: this.getProductTypes.bind(this),
      },
      {
        name: 'dimension65',
        getValue: this.getBackgroundColor.bind(this),
      },
      {
        name: 'dimension73',
        getValue: this.getCustomTrackingIds.bind(this),
      },
      {
        name: 'dimension75',
        getValue: this.getLabelsAndPromos.bind(this),
      },
      {
        name: 'dimension76',
        getValue: this.getUrls.bind(this),
      },
      {
        name: 'dimension90',
        getValue: this.getElementIds.bind(this),
      },
      {
        name: 'dimension105',
        getValue: this.getMerchantIds.bind(this),
      },
      {
        name: 'dimension111',
        getValue: this.getStartDate.bind(this),
      },
      {
        name: 'dimension115',
        getValue: this.getClickType.bind(this),
      },
      {
        name: 'dimension116',
        getValue: this.getReviewCodes.bind(this),
      },
      {
        name: 'dimension117',
        getValue: this.getReviewCounts.bind(this),
      },
      {
        name: 'dimension118',
        getValue: this.getReviewScores.bind(this),
      },
      {
        name: 'dimension124',
        getValue: this.getWasPrices.bind(this),
      },
      {
        name: 'dimension125',
        getValue: this.getEndDate.bind(this),
      },
      {
        name: 'metric1',
        getValue: this.getLoadTime.bind(this),
      },
      {
        name: 'metric24',
        getValue: this.getViewportTime.bind(this),
      },
      {
        name: 'metric25',
        getValue: this.getTimeRemaining.bind(this),
      },
      {
        name: 'metric27',
        getValue: this.getPageHeight.bind(this),
      },
      {
        name: 'metric28',
        getValue: this.getElementYPosition.bind(this),
      },
    ];
  }
}
