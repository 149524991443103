/**
 * Get the gclid from the  sessionStorage of the browser or chck if present in the url parameters, but mock the value for tests - used in generateDataHash
 * @returns {*}
 */
export default (): string => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
    const value =
      window.sessionStorage &&
      (window.sessionStorage.gclid || window.sessionStorage.wbraid || window.sessionStorage.gbraid);
    if (value && value !== 'Invalid') {
      return value;
    }
    if (window.location && window.location.search) {
      const urlParametersString = window.location.search;
      const urlParametersFormatted = new URLSearchParams(urlParametersString);
      const id =
        (urlParametersFormatted &&
          (urlParametersFormatted.get('gclid') ||
            urlParametersFormatted.get('wbraid') ||
            urlParametersFormatted.get('gbraid'))) ||
        '';

      return id;
    }
    return '';
  }
  return '';
};
