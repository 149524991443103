import Uri from 'urijs';
import BaseMerchant from './BaseMerchant';
import type { Site } from '../../types/Site';

export default class BolComMerchant extends BaseMerchant {
  accountId?: string | number;

  constructor(
    id: number,
    name: string,
    hostname: string,
    path?: string | null,
    site?: Site,
    accountId?: string | number,
  ) {
    super(id, name, hostname, site, path);
    this.accountId = accountId;
  }

  rewrite(url: string): string {
    if (!this.accountId) {
      return url;
    }

    const urlObject = new Uri('https://partnerprogramma.bol.com/click/click');

    const replacements = {
      p: 1,
      t: 'url',
      f: 'TXL',
      s: this.accountId,
      subid: 'hawk-custom-tracking',
      url,
    };

    Object.keys(replacements).forEach((key) => {
      urlObject.addQuery(key, replacements[key]);
    });

    return urlObject.toString();
  }
}
