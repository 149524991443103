// whatwg-fetch is a global polyfill, so has no export so we simply import the module
// https://github.com/github/fetch/issues/275#issuecomment-181784694
import 'whatwg-fetch';
import { buildUrlHawklinks } from '../../utils/buildUrl';
import type { MerchantsRequestParameters } from '../../types/MerchantsRequestParameters';

type GetJSONParams = MerchantsRequestParameters;

/**
 * CORS AJAX get JSON, no jQuery
 */
export const getJSON = async <T>(
  url: string,
  params: GetJSONParams,
): Promise<T | Record<string, never>> => {
  if (typeof fetch !== 'undefined') {
    // Send the request
    try {
      const response = await fetch(buildUrlHawklinks(url, params));
      if (response && response.json) {
        return await response.json();
      }
      return {};
    } catch (e) {
      return {};
    }
  } else {
    return {};
  }
};

export const postJSON = async <T>(
  url: string,
  data: Record<string, unknown>,
  contentType: string,
): Promise<T | Record<string, never>> => {
  // Send the request
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: ((): BodyInit => {
        switch (contentType) {
          case 'json':
            return JSON.stringify(data);
          default:
            return Object.keys(data).reduce((result, key) => {
              if (result.length > 0) {
                result += '&';
              }
              result += `${key}=${data[key]}`;
              return result;
            }, '');
        }
      })(),
      headers: new Headers({
        'Content-type': ((): string => {
          switch (contentType) {
            case 'json':
              return 'application/json';
            default:
              return 'application/x-www-form-urlencoded';
          }
        })(),
      }),
    });
    switch (contentType) {
      case 'json':
        return await response.json();
      default:
        return {};
    }
  } catch (e) {
    return {};
  }
};
