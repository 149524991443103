import { HawkEvents } from '../../types/HawkEvents';
import { Site } from '../../types/Site';
import HawkLinks from '../../utils/hawklinks';
import SkimLinks from '../../utils/skimlinks';

import { getLinks } from './getLinks';
import processLinks from './processLinks';

interface ProcessArticleEvent {
  element: HTMLElement;
}

interface HandleInfiniteSrollArticleParams {
  selector: string;
  hawkLinks: HawkLinks;
  skimLinksWithDomainCheck: SkimLinks;
  site: Site;
  area: string;
  articleName: string;
  articleUrl: string;
  articleId: string | null;
}

export const handleInfiniteScrollArticle = ({
  selector,
  hawkLinks,
  skimLinksWithDomainCheck,
  site,
  area,
  articleName,
  articleUrl,
  articleId,
}: HandleInfiniteSrollArticleParams): void => {
  document.addEventListener(
    HawkEvents.HAWK_PROCESS_ARTICLE,
    async (event: CustomEventInit<ProcessArticleEvent>) => {
      if (event.detail?.element) {
        // Get links that are not in a widget.
        const { linksToProcess, widgets } = getLinks(selector, event.detail.element);

        // Rewrite existing links with HawkLinks + SkimLinks right away
        await processLinks(
          linksToProcess,
          [hawkLinks, skimLinksWithDomainCheck],
          site,
          area,
          articleName,
          articleUrl,
          widgets,
          articleId,
        );
      }
    },
  );
};
