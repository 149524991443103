import Uri from 'urijs';
import BaseMerchant from './BaseMerchant';
import type { Site } from '../../types/Site';

export default class PartnerAdsMerchant extends BaseMerchant {
  mid: string | number;

  trackingId?: string | number;

  account: string | undefined;

  constructor(
    id: number,
    hostname: string,
    mid: string | number,
    path?: string | null,
    targetDomain?: string | null,
    site?: Site,
    trackingId?: string | number,
    parameters?: Record<string, string>,
  ) {
    super(id, hostname, hostname, site, path);
    this.mid = mid;
    this.trackingId = trackingId;
    this.trackedLinkPattern = targetDomain || 'www.partner-ads.com';

    if (parameters && typeof parameters.country_code !== 'undefined') {
      this.account = parameters.country_code;
    }
  }

  rewrite(url: string): string {
    if (!this.account) {
      return url;
    }
    const newUrl = new Uri(`https://${this.trackedLinkPattern}/${this.account}/klikbanner.php`)
      .setQuery('partnerid', '28286')
      .setQuery('bannerid', String(this.trackingId))
      .setQuery('uid', 'hawk-custom-tracking')
      .setQuery('htmlurl', url);

    return newUrl.href();
  }
}
