import Promise from 'promise-polyfill';
import { markPerformanceAPI, measurePerformanceAPI } from '../../analytics-library/entry';
import init from './init';
import { getJSON } from './ajax';
import getUrlParameters from './getUrlParameters';
import { getSite } from '../../utils/getSite';
import getHawklinksStatus from '../../utils/getHawklinksStatus';
import getKeywords from './getKeywords';
import type { MerchantsResponse } from '../../types/MerchantsResponse';
import { MerchantDomain } from '../../utils/merchants';

export const callback = async (): Promise<void> => {
  // If hawklinks not blocked
  const keywords = await getKeywords();
  if (!getHawklinksStatus(keywords)) {
    const site = getSite(document.location.href, '', keywords);
    window.Promise = window.Promise || Promise;
    const urlParameters = getUrlParameters(['area']);
    const endpoint = window.hawk_api_endpoint || process.env.SEARCH_API_ENDPOINT;

    try {
      const {
        merchants,
        area,
        config = {} as MerchantsResponse['config'],
      } = await getJSON<MerchantsResponse>(`${endpoint}/merchant-domains.php`, {
        area: urlParameters.area,
        site: site.code && site.code.toUpperCase(),
      });

      // Initialise HawkLinks
      await init({
        site,
        merchants,
        area,
        tsid: config.tsid,
        skimlinksId: config.skimlinks_id,
      });
    } catch (_) {
      // Initialise HawkLinks
      await init({
        site,
        merchants: [] as MerchantDomain[],
        area: '',
      });
    }
  }
};

// Check if we need the polyfill
(async (): Promise<void> => {
  markPerformanceAPI('Entry script loaded', { detail: 'HAWKLINKS' });
  callback();
  markPerformanceAPI('Hawklinks process completed', { detail: 'HAWKLINKS' });
  measurePerformanceAPI('Time between from script loaded to rewritten link return', {
    start: 'Entry script loaded',
    end: 'Hawklinks process completed',
    detail: `HAWKLINKS`,
  });
})();
