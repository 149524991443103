import Uri from 'urijs';

export default class PartnerAdsReset {
  trackedLinkPattern: string;

  resetParam: string;

  constructor(targetDomain?: string) {
    this.trackedLinkPattern = targetDomain || 'partner-ads.com';
    this.resetParam = 'htmlurl';
  }

  getResetUrl(url: string): string | null | undefined {
    if (url.indexOf(this.trackedLinkPattern) !== -1) {
      const queryParam = new Uri(url)
        .query()
        .split('&')
        .filter((q) => q.indexOf(this.resetParam) === 0)
        .shift();

      if (queryParam) {
        const url = queryParam.split('=').pop();
        if (url) {
          return url;
        }

        return null;
      }

      return null;
    }

    return null;
  }
}
