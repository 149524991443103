import Uri from 'urijs';
import BaseMerchant from './BaseMerchant';
import type { Site } from '../../types/Site';

export default class ImpactRadMerchant extends BaseMerchant {
  mid: string | number | undefined;

  adId?: string | number;

  accountId?: string | number;

  constructor(
    id: number,
    name: string,
    hostname: string,
    mid: string | number | undefined,
    accountId?: string | number,
    adId?: string | number,
    targetDomain?: string,
    path?: string | null,
    site?: Site,
  ) {
    super(id, name, hostname, site, path);
    this.mid = mid;
    this.adId = adId;
    this.trackedLinkPattern = targetDomain;
    this.accountId = accountId;
  }

  rewrite(url: string): string {
    if (this.accountId && this.adId && this.trackedLinkPattern) {
      const urlObject = new Uri(
        `https://${this.trackedLinkPattern}/c/${this.accountId}/${this.adId}/${this.mid}`,
      );
      urlObject.addQuery('subId1', 'hawk-custom-tracking');
      urlObject.addQuery('sharedId', 'hawk-prefix');
      urlObject.addQuery('u', url);
      return urlObject.toString();
    }

    return url;
  }
}
