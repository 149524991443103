import { getHashHex } from './getHashHex';

export const getDataHash = async (data: Record<string, unknown>): Promise<string> => {
  // Encode data
  const dataString = JSON.stringify(data);
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(dataString);

  // Generate hash
  const hashHex = await getHashHex(encodedData);

  // Prefix with 0 if hash length is not even
  const sanitizedHashHex = `${hashHex.length % 2 ? '0' : ''}${hashHex}`;
  // Convert to decimal and extract the first 19 digits - to ensure nearly 64-bit range (up to 18,446,744,073,709,551,616)
  const bn = BigInt(`0x${sanitizedHashHex}`);

  return bn.toString(10).substring(0, 19);
};
