import getUrlParameters from './getUrlParameters';
import generateNumber from '../../utils/generateNumber';
import { postJSON } from './ajax';
import type { Site } from '../../types/Site';

export default (site: Site, links: HTMLAnchorElement[], widgets: HTMLElement[]): void => {
  // Send combined appear event to GA, but batch the events to avoid exceeding the payload size
  const actions = {};
  links
    // Widget links are (should be) handled by widget tracking - don't track here
    .filter((el) => !widgets.some((widget) => widget.contains(el)))
    .filter((el) => el.getAttribute('data-hl-processed'))
    .forEach((el) => {
      const action = el.getAttribute('data-action') || 'HawkLink';
      if (!actions[action]) {
        actions[action] = [];
      }
      if (action) {
        actions[action].push(el);
      }
    });

  // Store metrics for 1% of requests
  const { metrics } = getUrlParameters(['metrics']);
  const sendMetrics = ((): boolean => {
    if (metrics === 'disabled') {
      return false;
    }
    if (typeof metrics !== 'undefined' && parseInt(metrics, 10)) {
      return true;
    }
    return generateNumber(100) === 0;
  })();
  if (sendMetrics) {
    // Don't wait for the result of saving metrics
    postJSON<void>(
      `${process.env.METRICS_ENDPOINT}/send_metrics`,
      {
        site: site.code,
        load_time: parseInt(performance.now().toFixed(0), 10),
        type: 'hawklinks',
        es6: 1,
      },
      'json',
    );
  }
};
