import Uri from 'urijs';

export default class M101Reset {
  trackedLinkPattern: string;

  resetParam: string;

  constructor() {
    this.trackedLinkPattern = 'link.monetizer101.com';
    this.resetParam = 'sourceUrl';
  }

  getResetUrl(url: string): string | null {
    if (url.indexOf(this.trackedLinkPattern) !== -1) {
      const urlObject = new Uri(url);
      const queryObject = urlObject.query(true);
      const sourceUrl = queryObject[this.resetParam];

      if (Array.isArray(sourceUrl)) {
        return sourceUrl[0] !== null ? sourceUrl[0] : url;
      }
      return sourceUrl || url;
    }
    return null;
  }
}
