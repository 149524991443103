import Uri from 'urijs';

export default class GeoriotReset {
  trackedLinkPattern: string;

  resetParam: string;

  constructor() {
    this.trackedLinkPattern = 'target.georiot.com';
    this.resetParam = 'GR_URL';
  }

  getResetUrl(url: string): string | null {
    if (url.indexOf(this.trackedLinkPattern) !== -1) {
      const urlObject = new Uri(url);
      const queryObject = urlObject.query(true);

      if (queryObject[this.resetParam]) {
        return queryObject[this.resetParam];
      }
    }

    return null;
  }
}
