export default class PHGreset {
  trackedLinkPattern: string;

  resetParam: string;

  constructor() {
    this.trackedLinkPattern = 'prf.hn';
    this.resetParam = 'destination';
  }

  getResetUrl(url: string): string | null {
    if (url.indexOf(this.trackedLinkPattern) >= 0) {
      let destinationUrl;

      // Checks if the destination part of the url is encoded or not
      if (url.match(/destination:(https?%3A%2)/)) {
        destinationUrl = url.match(/destination:([&\w%.-]+)/);
      } else {
        destinationUrl = url.match(/destination:(.*$)/);
      }

      const decodedDestinationUrl = destinationUrl && decodeURIComponent(destinationUrl[1]);
      return decodedDestinationUrl || `${url}/pubref:hawk-custom-tracking`;
    }

    return null;
  }
}
