import Uri from 'urijs';
import BaseMerchant from './BaseMerchant';

export default class ScalaHostingMerchant extends BaseMerchant {
  rewrite(url: string): string {
    const urlObject = new Uri(url);
    const fragment = '5f50ee0e05dfe';
    urlObject.fragment(`${fragment}&data1=hawk-custom-tracking`);
    return urlObject.toString();
  }
}
