// We do share these two functions in one file as they use same utilities
import getUserAgent from '../../utils/getUserAgent';
import { getScope } from '../../utils/global';

/**
 * Add measures to UserTiming API
 * @param name
 * @param params
 */
const measurePerformanceAPI = (name, params): void => {
  const scope = getScope();
  const userAgent = getUserAgent();
  // This functionality breaks hawk on other browser, so limited just to Chrome
  if (scope && userAgent && userAgent.indexOf('Chrome') !== -1 && scope.performance) {
    scope.performance.measure(name, params);
  }
};

/**
 * Add marks to UserTiming API
 * @param name
 * @param params
 */
const markPerformanceAPI = (name, params): void => {
  const scope = getScope();
  const userAgent = getUserAgent();
  // This functionality breaks hawk on other browser, so limited just to Chrome
  if (scope && userAgent && userAgent.indexOf('Chrome') !== -1 && scope.performance) {
    scope.performance.mark(name, params);
  }
};

export { markPerformanceAPI, measurePerformanceAPI };
