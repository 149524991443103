import { createTimeOutedInterval } from '../createTimeOutedInterval/createTimeOutedInterval';

export const apstagCallback = (): void => {
  window.apstag.nativeContent().enable();
};

export const triggerApstag = (): void => {
  const isApstagQueueAvailable = (): boolean => {
    return !!window?.apstag?.queue;
  };
  const pushApstagTrigger = (): void => {
    const { apstag } = window;

    apstag.queue.push(apstagCallback);
  };

  // Theres an edge case where we run this code on a page which is using the old ad integration
  // which contains apstag but not the new property
  // or where the script has not been loaded/run on the page yet
  if (isApstagQueueAvailable()) {
    pushApstagTrigger();
    return;
  }

  createTimeOutedInterval(isApstagQueueAvailable, pushApstagTrigger);
};
